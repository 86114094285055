@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Manrope';
  font-weight: 400;
  src: local('Manrope'), url(./fonts/Manrope/Manrope-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope Medium';
  font-weight: 500;
  src: local('Manrope'), url(./fonts/Manrope/Manrope-VariableFont_wght.ttf) format('truetype');
}

@font-face {
    font-family: 'Manrope Bold';
    font-weight: 700;
    src: local('Manrope'), url(./fonts/Manrope/Manrope-VariableFont_wght.ttf) format('truetype');
}

* {
  font-family: 'Manrope Medium';
 
}
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #70eb13;
}

input:not(:checked) ~ .dot {
  transform: translateX(0%);
  background-color: #cecece;
}

.ant-menu-item span a {
  font-family: 'Manrope Bold';
  color: white !important;
}

.ant-tabs-nav-list .ant-tabs-tab div {
  font-family: 'Manrope Bold' !important;
}


.ant-card-body .ant-list-item {
  box-shadow: 3px 3px 8px #cecece
}

.top-scrollbars {
  transform: rotateX(180deg);
}
.inner-scrollbars {
  transform: rotateX(180deg);
}

body {
  background: #fff;
  overflow: hidden;
}

.logo {
  float: left;
  width: 70px;
  height: auto;
  margin: 20px 24px 16px 24px;
  padding-bottom: 20px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-header {
  height: 50px;
  line-height: 50px;
}

.not_visible {
  background: #eee;
}

.ant-layout {
  background: #fcfcfc!important;
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

.site-description-item-profile-wrapper {
  font-size: 14px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
  font-size: 16px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}

.site-description-item-profile-p {
  color: rgba(255,255,255,0.85);
}


.scrolling-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.scrolling-wrapper .ant-card {
  display: inline-block;
  width: 100px;
}

.fullscreen {
  width: 100%;
  overflow-y: auto;
}

*{padding:0;margin:0}.wrapper{padding-top: 100px;display:flex;justify-content:center;align-items:center;background-color:black}.checkmark__circle{stroke-dasharray: 166;stroke-dashoffset: 166;stroke-width: 2;stroke-miterlimit: 10;stroke: #7ac142;fill: none;animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards}.checkmark{width: 56px;height: 56px;border-radius: 50%;display: block;stroke-width: 2;stroke: #fff;stroke-miterlimit: 10;margin: 10% auto;box-shadow: inset 0px 0px 0px #7ac142;animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}.checkmark__check{transform-origin: 50% 50%;stroke-dasharray: 48;stroke-dashoffset: 48;animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards}@keyframes stroke{100%{stroke-dashoffset: 0}}@keyframes scale{0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}}@keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px #7ac142}}